
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.hm-form {
  &__field {
    display: flex;
    align-items: center;

    .esmp-upload {
      width: 100%;
    }

    .esmp-icon {
      flex-shrink: 0;
    }
  }
}
